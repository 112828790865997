import React from 'react';
import Splash from '../../components/splash/Splash';
import Content from '../../components/content/Content';
import Gallery from '../../components/gallery/Gallery';
import { Link } from 'gatsby';
import styles from './Services.module.scss';
import Portrait from '../../assets/images/silke.jpg';

const Services = () => {
    return (
        <React.Fragment>
            <Splash>
                <h1 className={styles.splashHeading}>Leistungen</h1>
            </Splash>
            <Content imageSrc={Portrait} imageFloat='left' title='Qualitätsversprechen' hideOnResponsive={false}>
                <p>Optimierte Patientenarbeiten mit der individuellen Handschrift eines erfahrenen Technikers.</p>
                <p>Nach vorheriger Absprache mit der Praxis findet die Farbnahme bei mir im Labor statt. Hier können die
                    Patienten auch Ihre Wünsche äußern und sich über Materialien informieren die für Ihren neuen
                    Zahnersatzes zum Einsatz kommen könnten.</p>
                <p>Die Farbrekonstruktion einzelner Zähne oder auch die Wahl der Farbe bei größeren Arbeiten ist
                    eine Herausforderung. Seit 2010 arbeite ich mit den Daten der digitalen Farbnahme mithilfe des
                    Olympus CrystalEye. Es bedarf einiger Übung und viel Erfahrung, um die Farbdaten in Zahnfarben
                    umzusetzen!</p>
            </Content>
            <Content imageFloat='right' title='Leistungsangebot'>
                <p>Die Dienstleistungen, die ich erbringen kann, umfassen:</p>
                <ul>
                    <li>Digitale Farbnahme und Dokumentation mit Olympus Crystaleye: für spezielle Farbanpassungen</li>
                    <li>Kronen/Brückenarbeiten
                        <ul>
                            <li>Vollkeramik
                                <ul>
                                    <li>Lithizumdisilikat</li>
                                    <li>Zirkoniumdioxid</li>
                                </ul>
                            </li>
                            <li>Metallverblendkeramik (VMK)
                                <ul>
                                    <li>NEM</li>
                                    <li>Edelmetalllegierungen</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>Implantatarbeiten:
                        <ul>
                            <li>Hybridabutments</li>
                        </ul>
                    </li>
                    <li>Ästhetikplanungen:
                        <ul>
                            <li>mittels Modellanaylse, Wax-up, Mock-up</li>
                        </ul>
                    </li>
                    <li>Marylandbrücken</li>
                    <li>Veneers, Table-Tops</li>
                    <li>Kosmetische Verblendschalen</li>
                </ul>
                <Link to='kontakt'>Kontaktieren Sie uns</Link> für weitere Informationen
            </Content>
            <Gallery />
        </React.Fragment>
    );
};

export default Services;
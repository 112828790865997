import React from 'react';
import styles from './Gallery.module.scss';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation';
import ToothImage1 from '../../assets/images/teeth1.jpg';
import ToothImage2 from '../../assets/images/teeth2.jpg';
import ToothImage3 from '../../assets/images/teeth3.jpg';
import ToothImage5 from '../../assets/images/teeth5.jpg';
import ToothImage6 from '../../assets/images/teeth6.jpg';
import ToothImage7 from '../../assets/images/teeth7.jpg';
import ToothImage8 from '../../assets/images/teeth8.jpg';

const Gallery = () => (
    <div className={styles.container}>
        <div className={styles.inner}>
            <AwesomeSlider 
                play={true}
                cancelOnInteraction={false}
                interval={6000}
                cssModule={AwsSliderStyles}
            >
                <div data-src={ToothImage1} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage2} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage3} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage5} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage6} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage7} className={styles.imageContainer}>

                </div>

                <div data-src={ToothImage8} className={styles.imageContainer}>

                </div>
            </AwesomeSlider>
        </div>
    </div>
);

export default Gallery;